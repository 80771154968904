import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HasAnyPermission from '../../../../base/components/UserProvider/HasAnyPermission';
import HasPermission from '../../../../base/components/UserProvider/HasPermission';
import {
  PlaylistTemplateFilterField,
  PlaylistTemplateListFragmentFragment,
  UserPermission,
  FolderFragment,
} from '../../../../graphql/graphql-operations';
import PlaylistTemplateFoldersList from '../../PlaylistTemplates/components/PlaylistTemplateFoldersList';
import PlayListTemplatesList from '../../PlaylistTemplates/components/PlaylistTemplatesList';
import UserTrainingList from '../../Trainings/components/UserTrainingList';
import SessionHistoryList from './SessionHistoryList';
import { UserDetailFilter } from './UserDetailTabs';

interface Props {
  activeTab: UserDetailFilter;
  userId?: string | null;
}

const UserDetailList = ({ activeTab, userId }: Props) => {
  const [selectedFolder, setSelectedFolder] = useState<FolderFragment | null>(
    null,
  );
  const navigate = useNavigate();
  const handlePlaylistClick = (
    playlist: PlaylistTemplateListFragmentFragment,
  ) => {
    navigate({
      pathname: '/playlist/create-session',
      search: `?userId=${userId}&templateId=${playlist.id}`,
    });
  };

  const handlePlaylistFolderClick = (folder: FolderFragment) => {
    setSelectedFolder((prevFolder) =>
      prevFolder && prevFolder.id === folder.id ? null : folder,
    );
  };

  switch (activeTab) {
    case UserDetailFilter.HISTORY:
      return <SessionHistoryList userId={userId!} />;
    case UserDetailFilter.TRAINING:
      return (
        <HasPermission val={UserPermission.VIEW_TRAINING_ASSIGNMENTS}>
          <UserTrainingList
            userId={userId}
            features={{ delete: true }}
            columns={{ xs: 1, sm: 3, lg: 4 }}
          />
        </HasPermission>
      );
    case UserDetailFilter.PLAYLISTS:
      return (
        <>
          <HasAnyPermission
            val={[
              UserPermission.READ_PLAYLIST_FOLDERS,
              UserPermission.MANAGE_PLAYLIST_FOLDERS,
            ]}
          >
            <PlaylistTemplateFoldersList
              sx={{ mb: 2 }}
              onClick={handlePlaylistFolderClick}
              searchConfig={{
                filterBy: PlaylistTemplateFilterField.NAME,
                filterValue: '',
              }}
              selectedFolder={selectedFolder}
            />
          </HasAnyPermission>
          <PlayListTemplatesList
            columns={{ xs: 1, sm: 3, lg: 4 }}
            sx={{ mt: 0 }}
            onClick={handlePlaylistClick}
            folderId={selectedFolder?.id || ''}
          />
        </>
      );
    default:
      return null;
  }
};

export default UserDetailList;
